import React, { useEffect, useState } from 'react'

import { addOrUpdateMatchInfo } from '../../../actions/social/leaderboard-actions'
import { defaultPlayerScore } from '../../../constants/constants'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { selectCurrentGamers } from '../../../selectors/current-play-selectors'
import { selectMatchStatus } from '../../../selectors/match-selectors'
import { selectCurrentUsername } from '../../../selectors/session-selectors'
import { GuestPlayer, MatchStatus, ScoreRank } from '../../../types'
import Util from '../../../util/util'
import PlayerEditModal from './PlayerEditModal'

type Props = {
  compoundMatchSlug: string
  onGuestOrderChange?: (newOrder: string[]) => void
  isAllowNewPlayer: boolean
}
type GuestPlayerItem = GuestPlayer & {
  id: string
  blasterIndex: number
  isGuest: boolean
  topScore: number
  topScoreRank: ScoreRank
}

const GuestPlayerSetup = ({ compoundMatchSlug, onGuestOrderChange, isAllowNewPlayer }: Props) => {
  const dispatch = useAppDispatch()
  const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
  const currentUsername = useAppSelector(selectCurrentUsername)
  const matchStatus: MatchStatus = useAppSelector(selectMatchStatus(matchOwner, matchSlug))

  const gamers = useAppSelector(selectCurrentGamers)
  const { leaderboard, players, info: matchInfo } = matchStatus
  const { guestOrder: currentGuestOrder = [] } = matchInfo
  const [guestPlayers, setGuestPlayers] = useState<GuestPlayerItem[]>([])
  const [selectedPlayerInfo, setSelectedPlayerInfo] = useState<GuestPlayer | null>(null)
  const [workingGuestOrder, setWorkingGuestOrder] = useState<string[]>(currentGuestOrder)
  const [isDirty, setIsDirty] = useState(false)
  const activeGamerIds = gamers.filter(({ isActive }) => isActive).map(({ gamerId }) => gamerId)

  useEffect(() => {
    setWorkingGuestOrder(currentGuestOrder)
  }, [currentGuestOrder])

  useEffect(() => {
    const currentGuestPlayerItems = workingGuestOrder.map((playerId) => {
      const playerInfo = matchOwner in players ? players[matchOwner][playerId] : null
      if (!playerInfo) {
        console.log(`player info missing for ${matchOwner}${playerId}`)
      }
      const {
        name,
        matchScore: { topScore, topScoreRank },
      } = playerInfo || defaultPlayerScore() // TODO: this shouldn't fail, but bugs can (have) caused it to; filter currentGuestPlayers first?
      const blasterIndex = activeGamerIds.indexOf(playerId)
      return {
        name,
        slug: playerId,
        id: `${currentUsername}/${playerId}`,
        isGuest: true,
        blasterIndex,
        topScore,
        topScoreRank,
      }
    })
    setGuestPlayers(currentGuestPlayerItems)
  }, [currentUsername, players, workingGuestOrder, gamers])

  const onSave = () => {
    const newMatchInfo = { ...matchInfo, guestOrder: workingGuestOrder }
    dispatch(addOrUpdateMatchInfo({ matchInfo: newMatchInfo }))
    setIsDirty(false)
  }
  const onShuffle = () => {
    const newOrder = Util.shuffleArray(guestPlayers.map(({ slug }) => slug))
    setWorkingGuestOrder(newOrder)
    if (onGuestOrderChange) {
      onGuestOrderChange(newOrder)
    }
    setIsDirty(true)
  }
  const onSort = () => {
    const sortedGuestPlayers: string[] = []
    leaderboard.forEach((compoundPlayer) => {
      const [username, player] = compoundPlayer.split('/')
      if (username === currentUsername && player !== currentUsername) {
        sortedGuestPlayers.push(player)
      }
    })
    setWorkingGuestOrder(sortedGuestPlayers)
    if (onGuestOrderChange) {
      onGuestOrderChange(sortedGuestPlayers)
    }

    setIsDirty(true)
  }

  const getPlayerEditModal = () => {
    if (selectedPlayerInfo === null) {
      return false
    }
    const onClose = () => {
      setSelectedPlayerInfo(null)
    }
    return (
      <PlayerEditModal
        matchSlug={compoundMatchSlug}
        player={selectedPlayerInfo}
        onClose={onClose}
      />
    )
  }
  const newPlayer = () => {
    /* TODO
      Currently, guest player ids are not unique across matches, only within matches.
      Perhaps they should be, but to keep things simple (from the host/player perspective),
      the ids aren't weird or even changeable, they're auto-assigned as player-1, player-2, etc.
      Guest players are not currently deletable, but since that may change, we can't assume that
      "guest-player count + 1" isn't already taken. This led me to the code below, which,
      as a side-effect, introduces a limit on the number guest players, which bears
      discussing at some point -- either before or after it ever gets hit :)
     */
    const guestPlayerCount = guestPlayers.filter(({ slug }) => !slug.startsWith('@')).length
    const MAX_GUESTS = 100
    let nextIndex = guestPlayerCount + 1
    let newSlug = ''

    while (nextIndex <= MAX_GUESTS && !newSlug) {
      const maybeNewSlug = `player-${nextIndex++}`
      if (!guestPlayers.find(({ slug }) => slug === maybeNewSlug)) {
        newSlug = maybeNewSlug
      }
    }
    if (!newSlug) {
      alert(`[${MAX_GUESTS}] guest player limit reached.`)
      return
    }
    const newPlayer = {
      slug: newSlug,
      name: `Player ${nextIndex - 1}`,
    }
    setSelectedPlayerInfo(newPlayer)
  }
  return (
    <>
      <div className="players">
        {isAllowNewPlayer && <button onClick={newPlayer}>New Player...</button>}
        {onGuestOrderChange && (
          <>
            <div>Players</div>
            <div>
              <button onClick={onShuffle}>Shuffle</button>
              <button onClick={onSort}>Sort</button>
              <button onClick={onSave} disabled={!isDirty}>
                Save Order
              </button>
            </div>
          </>
        )}
      </div>
      {getPlayerEditModal()}
    </>
  )
}

export default GuestPlayerSetup
