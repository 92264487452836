import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  defaultDirtyState,
  defaultGamerStatus,
  defaultPlaylistInfo,
  defaultTrackInfo,
} from '../constants/constants'
import {
  TrackInfo,
  PlaylistInfo,
  PlayStatus,
  DirtyState,
  ScoreDelta,
  GamerStatus,
  PlayStatusTrackInfo,
} from '../types'

export interface CurrentPlay {
  trackInfo: TrackInfo //ExtendedTrackInfo
  playlistInfo: PlaylistInfo
  trackDuration: number
  isPlaying: boolean
  clockStartAtSeconds: number
  gamers: GamerStatus[]
  isScoreDirty: boolean
  isPlayable: boolean
  isEditable: boolean
  dirtyState: DirtyState
  localAudioPath: string
  localImagePath: string
  localImageData: ArrayBuffer | string | null
  lyrics: string
  lrcLines: string[] | null
}
const initialState: CurrentPlay = {
  trackInfo: defaultTrackInfo,
  playlistInfo: defaultPlaylistInfo,
  trackDuration: 0.0,
  isPlaying: false,
  clockStartAtSeconds: 0.0,
  gamers: [defaultGamerStatus(0), defaultGamerStatus(1)],
  isPlayable: false,
  isEditable: false,
  isScoreDirty: false,
  dirtyState: defaultDirtyState,
  localAudioPath: 'none',
  localImagePath: 'none',
  localImageData: null,
  lyrics: '',
  lrcLines: null,
}
type GamerPayloadBase = {
  gamerIndex: number
}
type GamerStatusPayload = GamerPayloadBase & {
  gamerStatus: GamerStatus
}
type ControllerPlayStatusPayload = GamerPayloadBase & {
  playStatus: PlayStatus
}
type ControllerScoreDeltaPayload = GamerPayloadBase & {
  scoreDelta: ScoreDelta
}
type ControllerGamerIdPayload = GamerPayloadBase & {
  gamerId: string
}
type ControllerGamerNamePayload = GamerPayloadBase & {
  playerName: string
}
type ControllerMaxScoreUpdatePayload = GamerPayloadBase & {
  maxScore: number
}

const currentPlaySlice = createSlice({
  name: 'currentPlay',
  initialState,
  reducers: {
    setPlaylistInfo: (state: CurrentPlay, action) => {
      state.playlistInfo = { ...action.payload }
    },
    setTrackInfo: (state: CurrentPlay, action: PayloadAction<TrackInfo>) => {
      const trackInfo = { ...action.payload }
      const { slug: song, wordCount: numElements, duration } = trackInfo
      state.gamers.forEach((gamer) => {
        const playStatusTrackInfo: PlayStatusTrackInfo = {
          song,
          trackDuration: duration,
          numElements,
          maxScore: 0,
        }
        const { playStatus } = gamer
        gamer.playStatus = { ...playStatus, ...playStatusTrackInfo }
      })
      state.trackInfo = { ...trackInfo }
    },
    setTimedWordCount: (state: CurrentPlay, action: PayloadAction<number>) => {
      state.trackInfo.timedWordCount = action.payload
    },
    setLyrics: (state: CurrentPlay, action: PayloadAction<string>) => {
      state.lyrics = action.payload
    },
    setLRClines: (state: CurrentPlay, action: PayloadAction<string[] | null>) => {
      state.lrcLines = action.payload
    },
    setIsPlaying: (state: CurrentPlay, action: PayloadAction<boolean>) => {
      state.isPlaying = action.payload
    },
    setTrackDuration: (state: CurrentPlay, action: PayloadAction<number>) => {
      state.trackDuration = action.payload
    },
    setStartAtSeconds: (state: CurrentPlay, action: PayloadAction<number>) => {
      state.clockStartAtSeconds = action.payload
    },
    setGamerStatus: (state: CurrentPlay, action: PayloadAction<GamerStatusPayload>) => {
      const { gamerIndex, gamerStatus } = action.payload
      state.gamers[gamerIndex] = gamerStatus
    },
    setPlayStatus: (state: CurrentPlay, action: PayloadAction<ControllerPlayStatusPayload>) => {
      const { gamerIndex, playStatus } = action.payload
      state.gamers[gamerIndex].playStatus = playStatus
    },
    setScoreDelta: (state: CurrentPlay, action: PayloadAction<ControllerScoreDeltaPayload>) => {
      const {
        gamerIndex,
        scoreDelta: { isRight, newRank: blastRank },
      } = action.payload
      state.gamers[gamerIndex].lastBlast = {
        isRight,
        blastRank,
        isPressed: true,
      }
    },
    unPress: (state: CurrentPlay, action: PayloadAction<number>) => {
      const gamerIndex = action.payload
      const currLastBlast = state.gamers[gamerIndex].lastBlast
      state.gamers[gamerIndex].lastBlast = {
        ...currLastBlast,
        isPressed: false,
      }
    },
    setGamerId: (state: CurrentPlay, action: PayloadAction<ControllerGamerIdPayload>) => {
      const { gamerIndex, gamerId } = action.payload
      state.gamers[gamerIndex].gamerId = gamerId
    },
    setGamerName: (state: CurrentPlay, action: PayloadAction<ControllerGamerNamePayload>) => {
      const { gamerIndex, playerName } = action.payload
      state.gamers[gamerIndex].playerName = playerName
    },
    setGamerActive: (
      state: CurrentPlay,
      action: PayloadAction<{ gamerIndex: number; isActive: boolean }>
    ) => {
      const { gamerIndex, isActive } = action.payload
      state.gamers[gamerIndex].isActive = isActive
    },
    updateMaxScore: (state, action: PayloadAction<ControllerMaxScoreUpdatePayload>) => {
      const { gamerIndex, maxScore } = action.payload
      const newPlayStatus = {
        ...state.gamers[gamerIndex].playStatus,
        maxScore,
      }
      state.gamers[gamerIndex].playStatus = newPlayStatus
    },
    // updateScoreClock: (state, action) => {
    //   const { scoreSeconds, numLaps } = action.payload
    //   const newPlayStatus = {
    //     ...state.playStatus,
    //     scoreSeconds,
    //     numLaps,
    //   }
    //   state.playStatus = newPlayStatus
    // },
    setIsScoreDirty: (state: CurrentPlay, action: PayloadAction<boolean>) => {
      state.isScoreDirty = action.payload
    },
    setIsPlayable: (state: CurrentPlay, action: PayloadAction<boolean>) => {
      state.isPlayable = action.payload
    },
    setIsEditable: (state: CurrentPlay, action: PayloadAction<boolean>) => {
      state.isEditable = action.payload
    },
    setLocalAudioPath: (state: CurrentPlay, action: PayloadAction<string>) => {
      state.localAudioPath = action.payload
    },
    setLocalImagePath: (state: CurrentPlay, action: PayloadAction<string>) => {
      state.localImagePath = action.payload
    },
    setLocalImageData: (state: CurrentPlay, action: PayloadAction<ArrayBuffer | string | null>) => {
      state.localImageData = action.payload
    },
    setDirtyState: (state: CurrentPlay, action: PayloadAction<DirtyState>) => {
      state.dirtyState = { ...action.payload }
    },
    setIsInfoDirty: (state: CurrentPlay, action: PayloadAction<boolean>) => {
      state.dirtyState.isInfoDirty = action.payload
    },
    setIsAudioDirty: (state: CurrentPlay, action: PayloadAction<boolean>) => {
      state.dirtyState.isAudioDirty = action.payload
    },
    setIsImageDirty: (state: CurrentPlay, action: PayloadAction<boolean>) => {
      state.dirtyState.isImageDirty = action.payload
    },
    setIsLyricsDirty: (state: CurrentPlay, action: PayloadAction<boolean>) => {
      state.dirtyState.isLyricsDirty = action.payload
    },
    setIsTimingDirty: (state: CurrentPlay, action: PayloadAction<boolean>) => {
      state.dirtyState.isTimingDirty = action.payload
    },
  },
})

export default currentPlaySlice
