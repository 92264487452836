import { selectCurrentMatchSlug, selectCurrentUsername } from './session-selectors'
import { RootState } from '../reducers'
import { DirtyState, TrackInfo } from '../types'
import { defaultDirtyState } from '../constants/constants'

const selectLocalTrack = (slug: string) => (state: RootState) => state.localAuthoring.tracks[slug]
const selectLocalTracks = (state: RootState) => state.localAuthoring.tracks
const selectIsInLocalTracks = (slug: string) => (state: RootState) =>
  slug in state.localAuthoring.tracks
const selectLocalTimings = (state: RootState) => state.localAuthoring.timings

const isDirty = (dirtyState: DirtyState = defaultDirtyState) => {
  const { isAudioDirty, isInfoDirty, isLyricsDirty, isTimingDirty } = dirtyState
  return isAudioDirty || isInfoDirty || isLyricsDirty || isTimingDirty
}

const selectIsLocalTrackDirty = (slug: string) => (state: RootState) => {
  const localTrack = state.localAuthoring.tracks[slug]
  if (!localTrack) {
    return false
  }
  const { dirtyState } = localTrack
  return isDirty(dirtyState)
}

const selectIsLocalPlaylistDirty = (trackSlugs: string[]) => (state: RootState) => {
  return trackSlugs.some((trackSlug: string) => {
    const localTrack = state.localAuthoring.tracks[trackSlug]
    if (!localTrack) {
      return false
    }
    const { dirtyState } = localTrack
    return isDirty(dirtyState)
  })
}

const selectLocalTrackInfoForState =
  (trackSlug: string) =>
  (state: RootState): TrackInfo => {
    const matchSlug = selectCurrentMatchSlug(state)
    const localTrack = selectLocalTrack(trackSlug)(state)
    const username = selectCurrentUsername(state)
    const {
      artist = '',
      title = '',
      owner = username,
      links = '',
      remotePath,
      remoteImagePath,
      wordCount = 0,
      timedWordCount = 0,
      duration = 0,
    } = localTrack
    const maxPoints = duration > 0 ? Math.floor((10 * wordCount) / duration) * wordCount : 0

    return {
      slug: trackSlug,
      artist,
      duration,
      title,
      owner,
      timestamp: Date.now(),
      links,
      remotePath,
      remoteImagePath,
      matchSlug,
      // playlistSlug,
      wordCount,
      timedWordCount,
      maxPoints,
    }
  }

export {
  selectIsInLocalTracks,
  selectIsLocalPlaylistDirty,
  selectIsLocalTrackDirty,
  selectLocalTimings,
  selectLocalTrack,
  selectLocalTrackInfoForState,
  selectLocalTracks,
}
