import { RootState } from '../reducers'
import {
  ActiveMatchInfo,
  GuestPlayer,
  InvitePath,
  MatchInfo,
  MatchStatus,
  MatchStatusMap,
  MinimalPlaylistInfo,
  PlayerStatus,
  PlayerStatusMap,
  PlaylistInfoMap,
  ScoreCounters,
  PlayerTrackPath,
  PlayerPath,
} from '../types'
import { selectOwnedMatchSlug, selectTrackInfo } from './blaster-peer-selectors'
import {
  selectActiveMatches,
  selectArchivedMatches,
  selectCurrentUsername,
} from './session-selectors'
import { pathOr } from 'ramda'
import {
  defaultMatchInfo,
  defaultMatchStatus,
  defaultPlayerScore,
  defaultPlaylistInfo,
  defaultScoreCounters,
  defaultTrackInfo,
  HOST_VISIBILITY_HIDE,
} from '../constants/constants'

export const selectMatchTitle = (matchOwner: string, matchSlug: string) => (state: RootState) => {
  return pathOr('', ['matchStatus', matchOwner, matchSlug, 'info', 'title'], state)
}

export const selectOwnerMatchStatuses =
  (matchOwner: string) =>
  (state: RootState): MatchStatusMap =>
    pathOr({}, ['matchStatus', matchOwner], state)

export const selectMatchStatus =
  (matchOwner: string, matchSlug: string) =>
  (state: RootState): MatchStatus =>
    pathOr(defaultMatchStatus(), ['matchStatus', matchOwner, matchSlug], state)

export const selectMatchInfo =
  (matchOwner: string, matchSlug: string) =>
  (state: RootState): MatchInfo =>
    pathOr(defaultMatchInfo(), ['matchStatus', matchOwner, matchSlug, 'info'], state)

export const selectPlaylistMap =
  (compoundMatchSlug: string) =>
  (state: RootState): PlaylistInfoMap => {
    const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
    return pathOr({}, ['matchStatus', matchOwner, matchSlug, 'info', 'playlists'], state)
  }

export const selectGuestPlayers =
  (compoundMatchSlug: string) =>
  (state: RootState): GuestPlayer[] => {
    const currentUsername = selectCurrentUsername(state)
    const [matchOwner, matchSlug] = compoundMatchSlug.split('/')
    const matchStatus = selectMatchStatus(matchOwner, matchSlug)(state)
    const {
      info: { hostVisibility },
    } = matchStatus
    const currentUserPlayerScores: PlayerStatusMap = pathOr(
      {},
      ['players', currentUsername],
      matchStatus
    )
    const currentUserGuests: GuestPlayer[] = []
    const playerIds = Object.keys(currentUserPlayerScores)
    playerIds.forEach((playerId: string) => {
      const playerScore = currentUserPlayerScores[playerId]
      if (
        playerIds.length > 1 &&
        hostVisibility === HOST_VISIBILITY_HIDE &&
        playerId === currentUsername
      ) {
        return
      }
      currentUserGuests.push({ slug: playerId, name: playerScore.name || '' })
    })
    return currentUserGuests
  }

export const selectPlayerCountersForTrack =
  ({ matchOwner, matchSlug, player, username, trackSlug }: PlayerTrackPath) =>
  (state: RootState): ScoreCounters =>
    pathOr(
      defaultScoreCounters(),
      [
        'matchStatus',
        matchOwner,
        matchSlug,
        'players',
        username,
        player,
        'trackScores',
        trackSlug,
        'counters',
      ],
      state
    )

export const selectPlayerScore =
  ({ matchOwner, matchSlug, player, username }: PlayerPath) =>
  (state: RootState): PlayerStatus =>
    pathOr(
      defaultPlayerScore(),
      ['matchStatus', matchOwner, matchSlug, 'players', username, player],
      state
    )

export const selectPlayerTopScoreForTrack =
  ({ matchOwner, matchSlug, username, player, trackSlug }: PlayerTrackPath) =>
  (state: RootState): number =>
    pathOr(
      0,
      [
        'matchStatus',
        matchOwner,
        matchSlug,
        'players',
        username,
        player,
        'trackScores',
        trackSlug,
        'topScore',
      ],
      state
    )

export const selectUserMatchesInfo = (state: RootState) => {
  const activeMatches = selectActiveMatches(state)
  const activeMatchInfo = activeMatches.map(({ matchOwner, matchSlug, inviteKey }) => {
    const matchInfo = selectMatchInfo(matchOwner, matchSlug)(state)
    return { id: matchInfo.slug, ...matchInfo, matchOwner, matchSlug, inviteKey, isActive: true }
  })
  const archivedMatches = selectArchivedMatches(state)
  const archivedMatchInfo = archivedMatches.map(
    ({ matchOwner, matchSlug, inviteKey }: InvitePath) => {
      const matchInfo = selectMatchInfo(matchOwner, matchSlug)(state)
      return { id: matchInfo.slug, ...matchInfo, matchOwner, matchSlug, inviteKey, isActive: false }
    }
  )
  return activeMatchInfo.concat(archivedMatchInfo)
  // TODO: how to handle multiple different invites to same match
  // const activeMatchSlugs = activeMatches.map(({ matchSlug }) => matchSlug)
  // const dedupedArchivedMatchInfo = archivedMatchInfo.filter(({ matchSlug }) => {
  //   return activeMatchSlugs.includes(matchSlug)
  // })
  // return activeMatchInfo.concat(dedupedArchivedMatchInfo)
}

export const selectMatchGroups = (state: RootState) => {
  const activeMatchSlugs = selectActiveMatches(state)
  const currentUsername = selectCurrentUsername(state)
  const ownedMatchSlug = selectOwnedMatchSlug(state)
  const hiddenMatchSlugs = selectArchivedMatches(state).map(
    ({ matchOwner, matchSlug }: ActiveMatchInfo) => `${matchOwner}/${matchSlug}`
  )
  const hostedMatchSlugs: string[] = []
  const playingMatchSlugs: string[] = []
  activeMatchSlugs.forEach(({ matchOwner, matchSlug }) => {
    const compoundMatchSlug = `${matchOwner}/${matchSlug}`
    if (compoundMatchSlug !== ownedMatchSlug) {
      const whichArray = currentUsername === matchOwner ? hostedMatchSlugs : playingMatchSlugs
      whichArray.push(compoundMatchSlug)
    }
  })
  return {
    ownedMatchSlug,
    hostedMatchSlugs,
    playingMatchSlugs,
    hiddenMatchSlugs,
  }
}

export const selectOwnedMatchInfo = (state: RootState) => {
  const compoundMatchSlug = selectOwnedMatchSlug(state)
  const [owner, matchSlug] = compoundMatchSlug.split('/')
  const matchInfo = selectMatchInfo(owner, matchSlug)(state)
  return matchInfo as MatchInfo
}

export const selectOwnedTrackCounters = (trackSlug: string) => (state: RootState) => {
  const compoundMatchSlug = selectOwnedMatchSlug(state)
  const [owner, matchSlug] = compoundMatchSlug.split('/')
  return selectPlayerCountersForTrack({
    matchOwner: owner,
    matchSlug,
    username: owner,
    player: owner,
    trackSlug,
  })(state)
}
export const selectPlaylistTracks =
  (compoundMatchSlug: string, playlistSlug: string) => (state: RootState) => {
    const [owner, matchSlug] = compoundMatchSlug.split('/')
    const matchInfo = selectMatchInfo(owner, matchSlug)(state)
    const { playlists = {} } = matchInfo
    if (!(playlistSlug in playlists)) {
      console.log(`match ${compoundMatchSlug}: ${playlistSlug} missing from playlists`)
      return { tracks: [] }
    }
    const { trackOrder = [], perTrackPlayLimit } = playlists[playlistSlug] // default if playlist has no tracks TODO: prevent selectors from returning malformed data!
    const tracks = trackOrder.map((trackSlug) => {
      const trackInfo = selectTrackInfo(owner, trackSlug)(state)
      return trackInfo || defaultTrackInfo
    })
    return { tracks, perTrackPlayLimit }
  }

export const selectMatchTracks = (compoundMatchSlug: string) => (state: RootState) => {
  const [owner, matchSlug] = compoundMatchSlug.split('/')
  const matchInfo = selectMatchInfo(owner, matchSlug)(state)
  const { playlistOrder = [], playlists = {} } = matchInfo
  const matchTracks: MinimalPlaylistInfo[] = []
  playlistOrder.forEach((playlistSlug, index) => {
    if (!(playlistSlug in playlists)) {
      console.log(`match ${compoundMatchSlug}: ${playlistSlug} missing from playlists`)
      return
    }
    const { trackOrder = [], slug, title, perTrackPlayLimit } = playlists[playlistSlug] // default if playlist has no tracks TODO: prevent selectors from returning malformed data!
    const tracks = trackOrder.map((trackSlug) => {
      const trackInfo = selectTrackInfo(owner, trackSlug)(state)
      return trackInfo || defaultTrackInfo
    })
    matchTracks.push({
      tracks,
      slug,
      title,
      perTrackPlayLimit,
      index,
    })
  })
  return matchTracks
}

export const selectPeerPlaylistInfo =
  (matchOwner: string, matchSlug: string, playlistSlug: string) => (state: RootState) =>
    pathOr(
      defaultPlaylistInfo,
      [matchOwner, matchSlug, 'info', 'playlists', playlistSlug],
      state.matchStatus
    )
