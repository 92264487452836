import React from 'react'

// import {getSpotifyUriFromTrackInfo} from '../../util/track-utils'
// import {useAppSelector} from '../../hooks'
// import {selectCurrentTrackImageUrl} from '../../selectors/session-selectors'
import Util from '../../util/util'
import { TrackPlayerCell } from '../../selectors/leaderboard-selectors'

type Props = {
  statsCell: TrackPlayerCell
}
const TrackBadge: React.FC<Props> = ({ statsCell: { trackInfo, title: cellTitle } }) => {
  if (!trackInfo) {
    return (
      <div className="total">
        <div>{cellTitle}</div>
      </div>
    )
  }
  const {
    // slug: trackSlug,
    title = '[no track]',
    artist,
    wordCount,
    duration,
  } = trackInfo
  // const spotifyUri = getSpotifyUriFromTrackInfo(trackInfo)
  // const isUseSpotifyImage = hasSpotifyToken && spotifyUri
  // const trackImageUrl = useAppSelector(selectCurrentTrackImageUrl)
  const possibleTitleParts = title.split(' | ')
  const [trackArtist, trackTitle] =
    possibleTitleParts.length === 2 ? possibleTitleParts : [artist, title]
  const trackPointInfo = () => {
    const durationClockTime = Util.secondsToClock(duration, false, false)
    const pointsPerTrack = Math.floor((10 * wordCount) / duration)
    const pointsPerTrackStr = duration > 0 ? pointsPerTrack : '-'
    const maxScore = pointsPerTrack * wordCount
    const pointsStr = duration > 0 ? maxScore : '-'
    const durationStr = duration > 0 ? durationClockTime : '-'
    const divider = <span>{' / '}</span>

    return (
      <div className="trackInfo">
        <div className="score">
          <div className="points">{pointsStr}</div>
        </div>
        <div className="pointInfo">
          <span>{durationStr}</span>
          {divider}
          <span>{wordCount}</span>
          {divider}
          <span>{pointsPerTrackStr}</span>
        </div>
      </div>
    )
  }

  return (
    <div className="trackBadge">
      {/*{isUseSpotifyImage && (*/}
      {/*  <div className="spotifyInfo">*/}
      {/*    <img src={trackImageUrl} alt="" />*/}
      {/*  </div>*/}
      {/*)}*/}
      <div className="currTrackTitle">
        <button className="title">{trackTitle}</button>
        <span className="artist">{trackArtist}</span>
      </div>
      {trackPointInfo()}
    </div>
  )
}

export default TrackBadge
