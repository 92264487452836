import React from 'react'

import { useAppDispatch, useAppSelector } from '../../../hooks'
import { selectAudienceFlags, selectPlayerVisibility } from '../../../selectors/session-selectors'
import { CheckboxWithLabel } from '../../widgets/TextInputWithLabel'
import sessionSlice from '../../../reducers/sessionSlice'

const VisibilitySetting = () => {
  const dispatch = useAppDispatch()
  const isPubMode = useAppSelector(selectAudienceFlags).isPub
  const playerVisibility = useAppSelector(selectPlayerVisibility)
  const { isShowGuestPlayers, isShowHostPlayer, isShowUserPlayers } = playerVisibility

  if (isPubMode) {
    return null
  }
  const onToggleGuestPlayers = () => {
    dispatch(
      sessionSlice.actions.setPlayerVisibility({
        ...playerVisibility,
        isShowGuestPlayers: !isShowGuestPlayers,
      })
    )
  }
  const onToggleHostPlayer = () => {
    dispatch(
      sessionSlice.actions.setPlayerVisibility({
        ...playerVisibility,
        isShowHostPlayer: !isShowHostPlayer,
      })
    )
  }
  const onToggleOtherPlayers = () => {
    dispatch(
      sessionSlice.actions.setPlayerVisibility({
        ...playerVisibility,
        isShowUserPlayers: !isShowUserPlayers,
      })
    )
  }
  return (
    <div className="visibility">
      <CheckboxWithLabel
        label="Guests"
        value={isShowGuestPlayers}
        onChange={onToggleGuestPlayers}
      />
      <CheckboxWithLabel label="Others" value={isShowUserPlayers} onChange={onToggleOtherPlayers} />
      <CheckboxWithLabel
        label="Host"
        value={isShowHostPlayer}
        onChange={onToggleHostPlayer}
        // disabled={false && isHostUserCurrentGamer} // TODO: make sure no conflict with current blaster?
      />
    </div>
  )
}

export default VisibilitySetting
